.color__items {
  height: 100px;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  z-index: 999;
}
.color__item {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: block;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.4s;
}
.color__item:hover,
.color__item.active {
  transform: scale(1.2);
}
/* .test {
  cursor: url('./iconSvg.svg'), auto;
} */
.test2 {
  border: 1px dashed transparent;
}
.cards {
}
